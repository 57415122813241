@font-face {
font-family: '__endemic_museo_ab7827';
src: url(/_next/static/media/9f158580aa959d78-s.p.woff) format('woff');
font-display: swap;
font-style: normal;
}@font-face {font-family: '__endemic_museo_Fallback_ab7827';src: local("Arial");ascent-override: 71.20%;descent-override: 14.24%;line-gap-override: 14.24%;size-adjust: 140.44%
}.__className_ab7827 {font-family: '__endemic_museo_ab7827', '__endemic_museo_Fallback_ab7827';font-style: normal
}.__variable_ab7827 {--endemic-museo: '__endemic_museo_ab7827', '__endemic_museo_Fallback_ab7827'
}

